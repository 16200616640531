import classNames from 'classnames';
import { UmbracoForm, Box } from '../';
import { differenceInDays } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';

const EnquiryPage2 = ({ data, destination, finish }) => {
  const { t } = useTranslation('common');
  const appInsights = useAppInsightsContext();
  const submitEvent = useTrackEvent(appInsights, 'Enquiry submited', {});

  const beforeSubmit = (values) => {
    submitEvent(values);
    if (destination) {
      values.destination = destination?.value;
    }
  };

  const preValidate = (fields, values, errors) => {
    if (values.arrivalDate && values.departureDate) {
      const dif = differenceInDays(values.arrivalDate, values.departureDate);
      if (dif > 0) {
        errors.departureDate = t('forms.enquiryform.$enquiryformDepartureDateGreaterThanArrivalDate');
      }
    }
  };

  return (
    <Box className="mx-auto flex max-w-[800px] flex-col items-center justify-center gap-10">
      <UmbracoForm
        className={classNames(
          'w-full [&_.column]:gap-03 [&_.fieldset-inner]:gap-03 [&_.page]:flex [&_.page]:flex-col [&_.page]:gap-03',
          "[&_.input-group_.label]:!body-04 mb-10 [&_input[type='text']]:bg-sandstone/60",
          '[&_.button-wrapper]:mt-0 [&_.checkbox>label]:w-[95%] [&_.field-iAgreeWithTheTerms]:mt-10',
        )}
        submitBtnClass="btn primary w-full mt-[54px]"
        successClass="text-center py-10 text-h3"
        formDefinition={data?.enquiryFormModule?.formFields}
        beforeSubmit={beforeSubmit}
        preValidate={preValidate}
        popoverProps={{ flipMainAxis: false, setMaxHeight: true, placement: 'bottom' }}
        onSubmitSuccessful={finish}
      />
    </Box>
  );
};

export default EnquiryPage2;
