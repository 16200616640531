import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ModalPortal, Stack, Box, HeadingTag, Text } from '../';
import { AnimatePresence } from 'framer-motion';
import { modalWrapper, modalBackdrop } from '@/animations';
import { useRouter } from 'next/router';
import EnquiryPage1 from './EnquiryPage1';
import EnquiryPage2 from './EnquiryPage2';
import EnquiryFinish from './EnquiryFinish';

import { pushValuesToRouteQuery, removeValuesFromRouteQuery } from '@/utils';
import { useTranslation } from 'next-i18next';
import Close from '@/assets/close.svg';
import Arrow from '@/assets/arrow.svg';

const Enquiry = ({ data }) => {
  const [animateDelay, setAnimateDelay] = useState(false);
  const [open, setOpen] = useState(false);
  const [destination, setDestination] = useState(null);
  const { t } = useTranslation('common');
  const router = useRouter();

  useEffect(() => {
    if (router.query?._enquiry) {
      setOpen(true);
      setAnimateDelay(true);
    }
  }, [router.query?._enquiry]);

  if (!data) return null;

  const destinationList = data.destinations?.map((destination) => ({
    value: destination.destination,
    label: destination.destination,
    selected: destination.isCurrentHotel,
    ...destination,
  }));

  const close = () => {
    removeValuesFromRouteQuery(router, { _enquiry: null });
    setAnimateDelay(false);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  const next = (dir = 1) => {
    pushValuesToRouteQuery(router, { _enquiry: parseInt(router?.query?._enquiry || 1) + dir });
  };

  const finish = () => {
    pushValuesToRouteQuery(router, { _enquiry: 'finish' });
  };

  let currentStepIndex = parseInt(router?.query?._enquiry || 1);
  if (data.displayOnePage) currentStepIndex = 2;

  return open ? (
    <ModalPortal>
      <Stack
        motion={modalWrapper}
        className={classNames('disable-scroll fixed top-0 z-50 flex h-svh w-screen flex-col items-center lg:h-screen')}
      >
        <AnimatePresence>
          {animateDelay && (
            <>
              <Box motion={modalBackdrop} className="absolute size-full bg-black/75" onClick={close} />

              <Box
                motion={modalBackdrop}
                className="relative size-full px-4 py-4 lg:px-[144px] lg:py-[131px]"
                // className="relative size-full px-4 py-4"
              >
                <div
                  className={classNames(
                    'transiation-all relative flex h-full w-full flex-col items-center p-0 duration-700 lg:px-20 lg:pt-10',
                    router?.query?._enquiry === 'finish' ? 'bg-bone' : 'bg-primary',
                  )}
                >
                  <div className="flex w-full justify-between p-4 pb-2 lg:p-0 lg:pb-0">
                    <div className="left-12 top-10 z-10 sm:right-8 sm:top-8 lg:absolute lg:right-10 lg:top-10">
                      {currentStepIndex >= 2 && data.displayOnePage == false && (
                        <button className="btn tertiary" onClick={() => next(-1)}>
                          <Arrow role="presentation" aria-label="Back" className="rotate-180" />
                          {t('general.$back')}
                        </button>
                      )}
                    </div>

                    <div className="right-12 top-10 z-10 sm:right-8 sm:top-8 lg:absolute lg:right-10 lg:top-10">
                      <button className="btn icon dark size-12 p-0" onClick={close}>
                        <Close role="presentation" aria-label="Close" />
                      </button>
                    </div>
                  </div>

                  {currentStepIndex === 1 && (
                    <HeadingTag
                      animate
                      className="title-05 px-4 py-2 font-kepler text-charcoal lg:pb-5 lg:pt-14"
                      data={data.headingTitle}
                    />
                  )}

                  {currentStepIndex === 2 && data.displayOnePage === true && router?.query?._enquiry !== 'finish' && (
                    <HeadingTag
                      animate
                      className="title-05 w-full max-w-[800px] px-4 pt-2 font-kepler text-charcoal lg:px-0 lg:pb-05 lg:pt-14"
                      data={data.headingTitle}
                    />
                  )}

                  {currentStepIndex === 2 && data.displayOnePage === false && (
                    <>
                      <HeadingTag
                        animate
                        className="title-05 w-full max-w-[800px] px-4 pt-2 font-kepler text-charcoal lg:px-0 lg:pb-05 lg:pt-14"
                        data={{
                          ...data.headingTitle,
                          heading: t('forms.enquiryform.$heading', { property: destination?.label }),
                        }}
                      />

                      <Text
                        className="body-03 w-full max-w-[800px] px-4 pb-2 pt-6 lg:px-0 lg:pb-05 lg:pt-0"
                        dangerouslySetInnerHTML={{ __html: data?.enquiryFormModule?.text }}
                      />
                    </>
                  )}

                  {currentStepIndex > 0 && router?.query?._enquiry !== 'finish' && (
                    <div
                      data-lenis-prevent
                      className="scrollbar-thumb-rounded-full h-full w-full overflow-y-auto p-4 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-charcoal/25 lg:p-0"
                    >
                      {currentStepIndex === 1 && (
                        <EnquiryPage1
                          data={data}
                          destinationList={destinationList}
                          destination={destination}
                          setDestination={setDestination}
                          next={next}
                        />
                      )}
                      {currentStepIndex === 2 && <EnquiryPage2 data={data} destination={destination} finish={finish} />}
                    </div>
                  )}

                  {router?.query?._enquiry === 'finish' && <EnquiryFinish destination={destination} />}
                </div>
              </Box>
            </>
          )}
        </AnimatePresence>
      </Stack>
    </ModalPortal>
  ) : null;
};

export default Enquiry;
